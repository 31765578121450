.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
    margin-top: 20px;
  }
}

.radioInputOptions {
  display: flex;
  align-items: center;
}

.marginRight {
  margin-right: 80px;
}

.noMarginBottom {
  margin-bottom: 0;
}