.avatarBase {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background: linear-gradient(180deg, #48B8F1 0%, #1C7ECF 100%);
  
  color: var(--matterColorLight);
}

.avatarBase:hover{
  color: var(--matterColorLight);
}

.avatarBase:hover {
  text-decoration: none;
}
/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}

.mediumAvatar .initials {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;
}

.largeAvatar .initials {
  font-size: 24px;
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -.9px;
  @media (--maxViewportSmall) {
    font-size: 18px;
    letter-spacing: -0.4px;
  }
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
