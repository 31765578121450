.keywordsContainer ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding: 5px;
}

.keywordsContainer ul li {
  flex: 1 1 auto;
  margin: 5px;
  font-size: 14px;
  padding: 6px 1.6rem;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 170ms;
  align-items: center;
  border: none;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  color: var(--marketplaceColor);
  box-shadow: inset 0 0 0 1px var(--marketplaceColor);
  min-width: 6.4rem;
  overflow: hidden;
  text-align: center;
  transition-property: background-color, box-shadow, color;
  vertical-align: middle;
}

.keywordsContainer ul li:hover {
  background-color: #00aff01c;
  color: var(--marketplaceColorDark);
  box-shadow: inset 0 0 0 2px var(--marketplaceColorDark);
}
