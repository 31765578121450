.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.noUpdate {
  color: var(--failColor);
  margin-top: 6px;
  font-size: 13px;
  overflow: hidden;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.instantPriceInput {
  flex-shrink: 0;
  width: 100%;
  margin-top: 10px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
  }
}

.listingTypeContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.listingInfo,
.listingTypeWarn {
  margin: 0;
  line-height: 1.5;
  font-size: 13px;
  overflow: hidden;
}

.listingTypeWarn {
  color: var(--failColor);
  margin-top: 10px;
}

.listingTypeDisabled {
  margin-bottom: 30px;
}

.listingTypeDisabled input {
  cursor: not-allowed;
}
