.root {
    /* Expand to available space */
    flex-grow: 1;
  
    /* Dimensions */
    width: 100%;
    height: auto;
  
    /* Layout */
    display: flex;
    flex-direction: column;
  
    @media (--viewportMedium) {
      padding-top: 1px;
    }
  }
  
  .features {
    margin-bottom: 15px;
  
    @media (--viewportMedium) {
      margin-bottom: 25px;
    }
  }
  
  .error {
    color: var(--failColor);
  }
  
  .submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
      margin-top: 100px;
    }
  }
  
  .radioInputOptions {
    display: flex;
    align-items: center;
  }
  
  .marginRight {
    margin-right: 80px;
  }
  
  .noMarginBottom {
    margin-bottom: 0;
  }