@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
  position: relative;
}

.password svg {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 7px;
  top: 36px;
  cursor: pointer;
  @media (--maxViewportMedium) {
    top: 25px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperLoginForm {
  margin-top: 160px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}

.loginBtn {
  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
  }
}
