@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  /* margin: 24px 0 0 0; */

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 16px 24px 24px 0;

    &:nth-child(even) {
      margin: 16px 0 24px 0;
    }
  }
}

.thumbnail:not(:first-of-type) {
  @media (--maxViewportSmall) {
    margin-top: 20px; 
  }
}

.starRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.conditionHeading {
  margin-right: 20px;
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
  overflow: hidden;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }

  @media (--maxViewportSmall) {
    margin: 0;
  }
}

.addImageWrapper:not(:first-of-type) {
  margin: 16px 0 0 0;
}

.addImageWrapper::after {
  content: '.';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorConcrete);

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

}

.addImage:hover {
  border-color: var(--matterColorAnti);
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightLight);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 3px;
  margin-bottom: 0;
  font-weight: var(--fontWeightLight);

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
  @media (--maxViewportMedium) {
    padding-top: 5px;
  }
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
    margin-top: 20px;
  }
}
