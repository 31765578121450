@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {}

.pageRoot {
    display: flex;
    padding-bottom: 90px;

    @media (--viewportLarge) {
        padding-bottom: 0;
    }
}

.reviewsWrapper{
    display: flex;
    align-items: center;
}
.actionError {
    @apply --marketplaceH5FontStyles;
    color: var(--failColor);
    margin: 0 0 11px 0;
  
    @media (--viewportMedium) {
      margin: 0 0 10px 0;
    }
    @media (--viewportLarge) {
      margin: 0;
    }
  }
.reviewStars {
    display: flex;
  
    @media (--viewportMedium) {
      margin: 5px 0;
    }
}

.reviewStars svg{
    width: 16px;
    margin-right: 2px;
}

.reviewsCount{
    font-size: 13px;
}

.loadingText {
    margin: 24px;
}

.errorText {
    color: var(--failColor);
    margin: 24px;
}

.sectionImages .backBtn {
    outline: none;
    border: none;
    padding: 0;
    margin-bottom: 10px;
    font-size: 15px;
    letter-spacing: -0.1px;
    cursor: pointer;
    color: #000000;

    @media (--maxViewportMedium) {
        margin-bottom: 0;
        padding: 10px 8px;
    }
}

.sectionImages .backIcon {
    width: 18px;
    height: 18px;
}

.threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        padding: 36px 36px 0 36px;
    }
}

.biddingThreeToTwoWrapper,
.ValuationThreeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        padding: 0 36px 0 36px;
    }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
    position: relative;
    /* allow positioning own listing action bar */
    padding-bottom: 66.6667%;
    /* 3:2 Aspect Ratio */
    background-color: var(--matterColorNegative);
    /* Loading BG color */

    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;

    @media (--viewportMedium) {
        padding-bottom: 0;
        /* No fixed aspect on desktop layouts */
    }
}

.imageWrapper {
    display: flex;
    justify-content: center;
}

.actionBar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 13px;
    left: 13px;
    width: calc(100% - 26px);
    color: var(--matterColorNegative);
    background-color: var(--matterColor);
    z-index: 1;
    /* bring on top of mobile image */

    /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
    cursor: initial;

    border-radius: var(--borderRadius);
}

.ownListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.ownListingTextPendingApproval {
    color: var(--attentionColor);
}
.loaderHeight {
    height: 38px;
}
.closedListingText {
    @apply --marketplaceH4FontStyles;
    margin: 14px 12px 11px 24px;
    text-align: center;
    width: 100%;

    @media (--viewportMedium) {
        margin: 25px 12px 22px 24px;
    }
}

.editListingLink {
    @apply --marketplaceH4FontStyles;
    flex-shrink: 0;
    margin: 0;
    padding: 14px 24px 11px 12px;
    color: var(--matterColorNegative);

    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        margin: 0;
        padding: 25px 24px 22px 12px;
    }
}

.editListingLink:hover {
    color: var(--matterColorLight);
    text-decoration: none;
}

.editIcon {
    margin: -6px 7px 0 0;
}

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        position: static;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        height: calc(0.41 * 100vw);
        max-height: 50vh;
        object-fit: cover;
        border-radius: var(--borderRadius);

        &:hover {
            transform: scale(1.005);
            box-shadow: var(--boxShadowListingCard);
        }
    }
}

.editListBtn {
    color: #212529;
}

.viewPhotos,
.editListBtn {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightMedium);

    /* Position and dimensions */
    position: absolute;
    bottom: 20px;
    right: 20px;
    margin: 0;
    padding: 8px 13px 6px 13px;

    /* Colors */
    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;
    transition: var(--transitionStyleButton);

    @media (--viewportMedium) {
        margin: 0;
    }
}

.editMsg {
    color: #000;
}

.viewPhotos:hover,
.editListBtn:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
}

.viewPhotos:only-child {
    right: 20px;
}

.editListBtn {
    right: 170px;

    @media (--maxViewportSmall) {
        right: 165px;
    }
}

.editListBtn:only-child {
	right: 20px;
}

.carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
    width: 100%;
    height: 100%;
}

.contentContainer {
    @media (--viewportMedium) {
        max-width: 1056px;
        margin: 0 auto;
        padding: 0 24px;
        display: flex;
        position: relative;
    }

    @media (--viewportLarge) {
        max-width: 1170px;
        padding: 0 36px;
        margin: 0 auto 117px;
    }

    @media (--viewportXLarge) {
        max-width: 1056px;
        padding: 0;
    }
}

.mainContent {
    flex-basis: 100%;
    margin-bottom: 23px;

    @media (--viewportMedium) {
        margin-top: 50px;
        margin-bottom: 51px;
        flex-shrink: 0;
    }

    @media (--viewportLarge) {
        margin-top: 50px;
        margin-bottom: 0px;
        /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
        max-width: calc(100% - 433px);
        flex-basis: calc(100% - 433px);
        flex-shrink: 0;
        flex-grow: 1;
    }
}

.bookingPanel {
    @media (--viewportLarge) {
        display: block;
        margin-top: 79px;
        margin-left: 60px;
        border-left-style: solid;
        border-left-width: 1px;
        border-left-color: var(--matterColorNegative);

        padding-left: 60px;

        /* The calendar needs 312px */
        flex-basis: 312px;
        flex-shrink: 0;
    }
}

.sectionAvatar {
    /* Position (over the listing image)*/
    margin-left: 24px;
    margin-top: -31px;

    /* Rendering context to the same lavel as listing image */
    position: relative;

    /* Flex would give too much width by default. */
    width: 60px;

    @media (--viewportMedium) {
        position: absolute;
        top: -49px;
        margin-left: 0;
        margin-top: 0;
    }
}

.avatarMobile {
    display: flex;

    @media (--viewportMedium) {
        display: none;
        /* Hide the medium avatar from the bigger screens */
    }
}

.avatarDesktop {
    display: none;
    /* Hide the large avatar from the smaller screens */

    @media (--viewportMedium) {
        display: flex;
    }
}

.sectionHeading {
    margin-top: 22px;
    margin-bottom: 34px;

    @media (--viewportMedium) {
        display: flex;
        margin-bottom: 60px;
    }
}

.desktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        margin: 0 48px 0 0;
        padding: 0;
    }
}

.desktopPriceValue {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: #329bdf;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 1px;
    }
}

.desktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.heading {
    margin-bottom: 33px;
    padding: 0 24px;

    @media (--viewportMedium) {
        margin-bottom: 0;
        padding: 0;
    }
}

.title {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--matterColor);

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.67;

    @media (--viewportMedium) {
        margin-top: -6px;
        margin-bottom: 2px;
    }

    @media (--maxViewportSmall) {
        font-size: 24px;
        line-height: 36px;
    }
}

.author {
    width: 100%;
    @apply --marketplaceH5FontStyles;
    margin-top: 7px;
    margin-bottom: 0;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--matterColor);
    }
}

.maxWidthWrapper {
    background-color: var(--matterColorLight);
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 36px 40px;
    max-width: 1400px;
    margin: 0 auto;

    @media (--maxViewportMedium) {
        padding: 20px 25px 25px;
    }
}

.biddingSectionHeading {
    @media (--viewportMedium) {
        display: flex;
    }
}

.biddingDesktopPriceContainer {
    display: none;

    @media (--viewportLarge) {
        /* Layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        padding: 0;
        margin-top: -5px;
    }
}

.biddingDesktopPriceValue {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: #329bdf;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 1px;
    }
}

.biddingDesktopPerUnit {
    /* Font */
    @apply --marketplaceH5FontStyles;
    color: var(--matterColor);
    font-weight: var(--fontWeightRegular);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.biddingHeading {
    margin-right: 30px;
}

.biddingHeadingMain,
.valuationHeadingMain {
    color: #b2b2b2;
    font-size: 18px;
    margin-right: 15px;
    font-weight: var(--fontWeightRegular);
    letter-spacing: -0.11px;

    @media (--maxViewportMedium) {
        font-size: 14px;
    }
}

.biddingHeadingCount,
.valuationHeadingCount {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Prompt;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    height: 40px;
    width: 165px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;

    @media (--maxViewportLarge) {
        width: 150px;
    }
}

.biddingTitle {
    /* Font */
    @apply --marketplaceModalTitleStyles;
    color: var(--matterColor);

    /* Layout */
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.67;
    font-size: 24px;
    font-weight: var(--fontWeightRegular);

    @media (--viewportMedium) {
        margin-top: -6px;
        margin-bottom: 2px;
    }

    @media (--maxViewportMedium) {
        font-size: 20px;
        line-height: 36px;
    }
}

.biddingSecondaryData {
    width: 100%;
    @apply --marketplaceH5FontStyles;
    margin-top: 7px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: var(--fontWeightRegular);
    color: var(--matterColorAnti);

    @media (--viewportDesktop) {
        font-size: 15px;
    }

    @media (--viewportMedium) {
        margin-top: 5px;
        margin-bottom: 0;
    }

    @media (--maxViewportMedium) {
        font-size: 14px;
    }
}

.biddingSecondaryData:last-of-type {
    margin-top: 10px;
}

.point_detail {
    margin: 18px 0;
}

.point_detail li {
    display: flex;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.09px;
    text-align: left;
    color: #4a4a4a;
    font-weight: normal;

    @media (--viewportDesktop) {
        font-size: 14px;
    }
}

.point_detail li .form_label {
    width: 50%;
    display: inline-flex;
    font-weight: var(--fontWeightSemiBold);
}

.point_detail li .show_label {
    width: 50%;
    display: inline-flex;
    padding-left: 15px;
}

.authorNameLink {
    color: var(--matterColor);
}

.contactWrapper {
    display: none;

    @media (--viewportMedium) {
        display: inline;
    }
}

.separator {
    margin: 0 6px;
}

.contactLink {
    @apply --marketplaceLinkStyles;
    @apply --marketplaceH5FontStyles;
    margin: 0;
}

.conditionImagesList {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -15px 15px;
}

.conditionImagesList li {
    display: flex;
    font-size: 16px;
    line-height: 2;
    letter-spacing: -0.09px;
    text-align: left;
    color: var(--matterColor);
    font-weight: var(--fontWeightRegular);
    width: 50%;
    padding: 0 15px;
    flex-direction: column;
    margin-top: 5px;

    @media (--viewportDesktop) {
        font-size: 14px;
    }
}

.conditionImagesList li .form_label {
    width: auto;
    margin-bottom: 8px;
}

.conditionImagesList li .imageListWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}

.conditionImagesList li .imageListWrapper>div {
    width: 50%;
    padding: 0 6px;
    margin-bottom: 12px;
    position: relative;
}

/* .conditionImagesList li .imageListWrapper > div::before{
  content: '';
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 4px;
  width: 20px;
  height: 20px;
  background-size: contain;
  transform: rotate(45deg);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root jss175 MuiSvgIcon-fontSizeSmall' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M13 6.99h3L12 3 8 6.99h3v10.02H8L12 21l4-3.99h-3z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
} */
.conditionImagesList li .imageListWrapper>div img {
    object-fit: contain;
    height: auto;
    position: static;
}

.sectionDescription {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 36px;
    }
}

.descriptionTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 13px;
    font-weight: var(--fontWeightLight);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
    }
}

.sectionFeatures {
    padding: 0 24px;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.imgNameWrapper,
.imgNameWrapperValuation {
    display: flex;
    align-items: center;
}

.initials,
.initialsValuation {
    font-size: 12px;
    font-weight: var(--fontWeightBold);
    text-transform: uppercase;

    @media (--maxViewportSmall) {
        font-size: 11px;
    }
}

.avatarBase,
.avatarBaseValuation {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    border-radius: 50%;

    /* Position possible initials to the center of the component */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    height: 40px;
    width: 40px;
    /* Colors */
    background: linear-gradient(180deg, #48b8f1 0%, #1c7ecf 100%);

    color: var(--matterColorLight);

    @media (--maxViewportSmall) {
        margin-right: 14px;
        width: 32px;
        height: 32px;
    }
}

.bidder,
.bidderValuation {
    font-size: 17px;
    min-width: 70px;
    display: inline-block;
    margin-right: 30px;
    font-weight: var(--fontWeightRegular);
    line-height: 20px;

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportMedium) {
        font-size: 14px;
    }
}

.dateDesktop,
.dateDesktopValuation {
    font-size: 13px;
    color: var(--matterColor);
    margin-right: 30px;
    font-weight: var(--fontWeightLight);
    line-height: 18px;

    @media (--viewportMedium) {
        display: inline;
    }
}

.nameDateWrapper {
    @media (--maxViewportLargeWithPaddings) {
        display: flex;
        flex-direction: column;
    }
}

.nameDateWrapper .dateDesktop {
    @media (--maxViewportLargeWithPaddings) {
        margin-top: 5px;
    }
}

.paymentWrapperMobile {
    display: none;

    @media (--maxViewportLarge) {
        display: block;
        margin-top: 20px;
    }

    @media (--maxViewportSmall) {
        margin-top: 15px;
    }

    @media screen and (max-width: 480px) {
        display: none;
    }
}

/* .dateMobile,
.dateMobileValuation {
  font-size: 13px;
  color: var(--matterColor);
  margin-right: 30px;
  font-weight: var(--fontWeightLight);
  line-height: 18px;
  @media (--viewportMedium) {
    display: none;
  }
  @media (--maxViewportSmall) {
    font-size: 12px;
  }
} */

.topBid {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 114px;
    color: var(--primaryColorDark);
    font-weight: 600;
    font-size: 11px;
    background: rgba(50, 155, 223, 0.17);
    border-radius: 2.76364px;
    margin-right: 30px;
    line-height: 18px;
    padding: 6px 10px;

    @media (--maxViewportMedium) {
        margin-right: 15px;
    }

    @media (--maxViewportSmall) {
        margin-right: 0;
        margin-left: 15px;
        min-width: auto;
    }
}

.statusesWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (--maxViewportLarge) {
        order: 2;
        width: 100%;
        align-items: stretch;
    }

    @media screen and (max-width: 480px) {
        order: 0;
        width: auto;
        align-items: center;
    }
}

.statusesWrapper .statusesInnerWrapper {
    display: flex;
    align-items: center;

    @media (--maxViewportLarge) {
        margin-top: 20px;
    }

    @media (--maxViewportSmall) {
        margin-top: 15px;
    }

    @media screen and (max-width: 480px) {
        margin-top: 0;
		margin-bottom: 10px;
    }
}

.statusText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 114px;
    font-weight: 600;
    font-size: 11px;
    border-radius: 2.76364px;
    margin-right: 30px;
    line-height: 18px;
    padding: 6px 10px;

    @media (--maxViewportMedium) {
        margin-right: 15px;
    }

    @media (--maxViewportSmall) {
        margin-right: 0;
    }

	/* @media screen and (max-width: 480px) {
		margin-bottom: 15px;
	} */
}

.statusText.pending {
    color: #775b08;
    background-color: #fff3cd;
}

.statusText.declined {
    color: #842029;
    background-color: #f8d7da;
}

.statusText.approved {
    color: #0f5132;
    background-color: #d1e7dd;
}

.loc,
.locValuation {
    @media (--maxViewportSmall) {
        font-size: 12px;
    }
}

.emptySection {
    width: 114px;
    height: 30px;
    margin-right: 30px;

    @media (--maxViewportSmall) {
        display: none;
    }
}

.bidPrice,
.valuationPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #4a4a4a;
    border: 1px solid #e6e6e6;
    font-size: 16px;
    min-width: 165px;
    padding: 9px 10px;
    border-radius: 4px;
    line-height: 20px;

    @media (--maxViewportMedium) {
        font-size: 14px;
        min-width: 150px;
    }

    @media (--maxViewportSmall) {
        font-size: 13px;
        padding: 8px;
    }

    @media screen and (max-width: 480px) {
        width: 50%;
    }
}

.acceptBid {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryColorDark);
    color: var(--matterColorLight);
    font-size: 16px;
    min-width: 165px;
    min-height: 40px;
    padding: 10px;
    line-height: 20px;
    /* margin-left: 30px; */
    border-radius: 4px;
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: -0.27px;

    @media (--viewportDesktop) {
        font-size: 14px;
    }

    @media (--maxViewportMedium) {
        min-width: 150px;
        font-size: 13px;
        margin-left: 15px;
        font-weight: var(--fontWeightRegular);
    }

    @media (--maxViewportSmall) {
        font-size: 13px;
        padding: 8px;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        display: none;
    }
}

.acceptBid:hover {
    cursor: pointer;
    background-color: var(--marketplaceColor);
}

.disabledPay,
.disabledPay:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
}

.sectionBiddingList,
.sectionValuationList {
    padding: 0 24px;
    margin-bottom: 32px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 52px;
    }
}

.biddingListWrapper {
    max-width: 1400px;
    margin: 0 auto;
}

.biddingListHeader,
.valuationListHeader {
    display: flex;
    justify-content: space-between;
    padding: 30px 25px 0;
    max-width: 1400px;
    margin: 0 auto;

    @media (--maxViewportMedium) {
        padding: 20px 10px 0;
        align-items: center;
    }

    @media (--maxViewportSmall) {
        padding: 20px 0 0;
    }
}

.biddingListContainer,
.valuationListContainer {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    max-width: 1400px;
    margin: 0 auto;

    @media (--maxViewportMedium) {
        padding: 0 10px;
    }

    @media (--maxViewportSmall) {
        padding: 0;
    }
}

.biddingListSection,
.valuationListSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    @media (--maxViewportLarge) {
        flex-wrap: wrap;
    }

    /* @media screen and (max-width: 480px) {
		flex-direction: column;
		align-items: flex-start;
	} */
}

.biddingListSection:not(:last-of-type),
.valuationListSection:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
}

.firstSection,
.firstSectionValuation,
.endSection,
.endSectionValuation {
    display: flex;
    align-items: center;
}

.acceptBidCover{
    min-width: 165px;
    margin-left: 30px;
}

.firstSection {
    @media (--maxViewportSmall) {
        margin-bottom: 15px;
    }
}

.endSection {
    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.endSection .acceptBid {
    @media (--maxViewportLarge) {
        display: none;
    }

    @media screen and (max-width: 480px) {
        display: flex;
        width: 50%;
    }
}

.featuresTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 16px;
    font-weight: var(--fontWeightLight);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
    }
}

.description {
    margin-top: 0;
    margin-bottom: 0;
    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
    font-size: 15px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
    }
}

.sectionMap {
    padding: 0 24px;
    margin-bottom: 35px;

    @media (--viewportMedium) {
        padding: 0;
        margin-bottom: 0;
    }
}

.locationTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);

    margin-top: 0;
    margin-bottom: 20px;
    font-weight: var(--fontWeightLight);

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 16px;
    }

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

.address {
    font-size: 15px;
    font-weight: var(--fontWeightMedium);

    @media (--maxViewportSmall) {
        font-size: 16px;
        margin-top: 0px;
    }
}

.sectionReviews {
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.reviewsHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);
    font-weight: var(--fontWeightLight);
    margin: 52px 0 20px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 50px 0 26px 0;
    }

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
    }
}

.sectionHost {
    position: relative;
    padding: 0 24px;
    margin-bottom: 5px;

    @media (--viewportMedium) {
        padding: 0;
    }

    @media (--viewportLarge) {
        margin-bottom: 3px;
    }
}

.yourHostHeading {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);
    font-weight: var(--fontWeightLight);
    margin: 54px 0 23px 0;

    @media (--viewportMedium) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportLarge) {
        margin: 53px 0 27px 0;
    }

    @media (--viewportDesktop) {
        font-size: 16px;
    }

    @media (--maxViewportSmall) {
        font-size: 14px;
    }
}

.modalContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Title of the modal */
.modalTitle {
    @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
    @apply --marketplaceModalParagraphStyles;
}

.enquiryModalContent {
    flex-grow: 1;
    display: flex;
}

.enquiryForm {
    flex-grow: 1;
    margin: 70px 0 100px 0;

    @media (--viewportMedium) {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        margin: 0;
    }
}

.enquirySubmitButtonWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 24px;
    background-color: var(--matterColorLight);

    @media (--viewportMedium) {
        position: static;
        padding: 0;
        margin-top: auto;
    }
}

.map {
    /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
    height: calc(100vh - 193px);
    width: 100%;

    /* Static map: dimensions are 640px */
    max-width: 640px;
    max-height: 640px;
    background-color: #eee;
    padding: 0;
    border: 0;
    cursor: pointer;

    @media (--viewportMedium) {
        height: 75vh;
    }

    @media (--viewportLarge) {
        height: 417px;
    }
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}

.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after,
.btn_main .loader.threeDots {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load-threeDots 1.5s infinite ease-in-out;
  animation: load-threeDots 1.5s infinite ease-in-out;
}
.btn_main .loader.threeDots {
  margin: auto;
  font-size: 5px;
  position: relative;
  top: -10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.btn_main .loader.threeDots:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.btn_main .loader.threeDots:after {
  left: 2.5em;
}
.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
@keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
