@import "../../styles/propertySets.css";

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
}

.root:hover {
  /* Remove link's hover effect */
  text-decoration: none;
}

.blogCard {
  margin-bottom: 80px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
}

.threeToTwoWrapper:hover {
  @media (--viewportMedium) {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: var(--borderRadius);
}

.blogDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  position: absolute;
  width: 261px;
  height: 140px;
  left: 18%;
  bottom: -60px;
  background: #fcfcfc;
  @media (--maxViewportMedium) {
    width: calc(100% - 20px);
    left: 0;
    right: 0;
    margin: 0 10px;
    box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: auto;
  }
}

.blogDetails p {
  font-weight: 600;
  font-size: 9.49565px;
  line-height: 14px;
  color: #329bdf;
  margin: 0 0 7px 0;
}

.blogDetails h3 {
  color: #222937;
  margin: 0 0 7px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blogDetails .author {
  font-weight: 300;
  font-size: 11.0783px;
  line-height: 17px;
  color: #222937;
}

.authorName {
  color: var(--marketplaceColor);
}
