@import '../../styles/propertySets.css';

.root {
  width: 100%;
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  height: calc(0.41 * 100vw);
  max-height: 40vh;
  object-fit: cover;
  min-height: 300px;
  border-radius: 10px;
  @media (--maxViewportMedium) {
    border-radius: 0;
    margin-bottom: 20px;
  }
}

.blogHeaderButtonLink {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.container {
  display: flex;
  align-items: center;

  @media (--maxViewportMSmall) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  max-width: 500px;
  min-width: 400px;
  height: calc(0.41 * 100vw);
  min-height: 300px;
  max-height: 40vh;
  padding: 1.6rem;
  @media (--maxViewportMedium) {
    max-width: 100%;
    width: 100%;
  }
}

.author {
  margin-bottom: 20px;
}
.author span {
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
}

.authorName {
  color: var(--marketplaceColor);
}

.description h3 {
  margin: 0 0 20px 0;
  color: #222937;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--maxViewportMedium) {
    font-size: 24px;
  }
}
.description p {
  color: #373f41;
  margin: 0 0 20px 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.description p:after {
  content: '\02026';
}
.readBtn {
  background: #329bdf;
  height: 44px !important;
  width: 162px !important;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.266667px;
  color: #ffffff;
  min-height: 44px !important;
}
.imageSection {
  width: 70%;
  min-width: 300px;
  height: 100%;
  @media (--maxViewportMSmall) {
    width: 100%;
  }
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
