.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 11px 24px 0 24px;
  }
  
  .form {
    flex-grow: 1;
  }
  
  .title {
    margin-bottom: 19px;
  
    @media (--viewportLarge) {
      margin-bottom: 38px;
      padding: 1px 0 7px 0;
    }
  }
  
  /* Radio buttons wrapper */

.radioButtonsWrapper{
  display: flex;
  align-items: center;
}

.radioButtonsWrapper .radioButtonBox{
  display: flex;
  align-items: center;
}

.radioButtonsWrapper .radioButtonBox:nth-of-type(1) {
  margin-right: 80px;
}

.radioButtonsWrapper .radioButtonBox .radioButtonInput{
  width: 13px;
  margin-right: 14px;
  cursor: pointer;
  -webkit-appearance: none;
  height: 13px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 50%;
  display: block;
  position: relative;
}

.radioButtonsWrapper .radioButtonBox .radioButtonInput:checked::before{
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: var(--primaryColorDark);
}

.radioButtonsWrapper .radioButtonBox label{
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor);
  margin-top: 0;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
}