@import '../../styles/propertySets.css';

.root {
}

.password {
  @apply --marketplaceModalPasswordMargins;
  margin-bottom: 120px;
}

.resetPasswordBtn{
  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
  }
}