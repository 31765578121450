.convertListingFormField {
  margin-top: 16px;
  margin-bottom: 20px;
  padding-top: 6px;

  @media (--viewportMedium) {
    margin-bottom: 20px;
    padding-top: 0;
  }
}
.bottomWrapper {
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 40px;
  }
}

.formMargins {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.convertButton {
  width: auto;
  padding: 0 60px;
  margin: 0 auto;

  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
    width: 100%;
  }
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  composes: formMargins;
}

.listingTypeWarn {
  color: var(--failColor);
  margin-top: 10px;
  margin: 0;
  line-height: 1.5;
  font-size: 13px;
  overflow: hidden;
}
