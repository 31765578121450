@import '../../styles/propertySets.css';

.root {
  width: 100%;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  min-height: 300px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 2rem 1.6rem;
}

.backBtn {
  outline: none;
  border: none;
  padding: 0;
  margin-bottom: 30px;
  font-size: 15px;
  letter-spacing: -0.1px;
  cursor: pointer;
  color: #000000;
  text-align: left;
  @media (--maxViewportMedium) {
    margin-bottom: 0;
  }
}

.backIcon {
  width: 18px;
  height: 18px;
}

.author span {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  @media (--maxViewportMedium) {
    font-size: 14px;
  }
}

.author .authorName {
  color: var(--marketplaceColor);
}

.mainContent h3 {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: 0.01em;
  color: #222937;
  @media (--viewportDesktop) {
    font-size: 40px;
    letter-spacing: 0.1px;
  }
  @media (--maxViewportMedium) {
    font-size: 24px;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 8px;
  }
}

.mainContent {
  margin-bottom: 35px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.imageWrapper img {
  width: 100%;

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.description {
  font-size: 16px;
  color: #222937;
  text-align: left;
  vertical-align: top;
  letter-spacing: 2%;
  line-height: 35px;
  @media (--maxViewportMedium) {
    font-size: 14px;
    line-height: 28px;
  }
}
