/* Profile menu */
.notificationMenu {
  height: auto !important;
}

.notificationMenuLabel {
  @apply --TopbarDesktop_linkHover;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 12px;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.notificationMenuLabel:active {
  border-bottom: 0;
}
.notificationMenuLabel:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.notificationMenuLabel:hover,
.notificationMenuLabel:focus {
  outline: 0;
}

.notificationMenuIsOpen:hover {
  border-bottom: 0;
}

.avatar {
  margin: 16px 0;
}

.notificationMenuContent {
  max-height: 300px;
  overflow-y: auto;
  min-width: 300px !important;
  border-radius: 4px !important;
}

.notificationMenuContent .notificationHeader h3{
  padding: 15px 20px;
  margin: 0;
  font-size: 15px;
  border-bottom: 1px solid var(--matterColorConcrete);
  background: var(--primaryColorDark);
  color: var(--matterColorLight);
  letter-spacing: .3px;
  font-weight: 600;
}

.count {
  display: flex;
  color: var(--matterColorLight);
  background-color: var(--attentionColor);
  height: 20px;
  padding: 0 6px;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: 500;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  top: 10px;
  right: 14px;
}

.notificationIcon {
  color: var(--successColor);
}

.notificationItem {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 20px;
}

.notificationDetail{
  width: 100%;
}

.notificationItem:not(:nth-last-of-type(1)) {
  border-bottom: 1px solid var(--matterColorConcrete);
}

.notificationUnread{
  background-color: rgba(50,155,223,.06);
}

.notificationItem:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.02);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.notificationLeftContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationLeftContainer h5{
  margin: 0;
  font-weight: 600;
  color: var(--matterColor);
  margin-right: 15px;
}

.notificationLeftContainer .notificationTime{
  margin: 0;
  font-size: 12px;
  line-height: normal;
  margin-top: 5px;
  white-space: nowrap;
}

.notificationMsg{
  font-size: 13px;
  line-height: normal;
  margin-top: 5px;
  color: var(--matterColor);
  margin-bottom: 0;
}

.notificationTime{
  color: var(--matterColorPlaceholder);
}

.noNotifications{
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  color: var(--matterColor);
}