@import '../../styles/propertySets.css';

.root p {
  @apply --marketplaceH4FontStyles;

  @media (--maxViewportSmall) {
    font-size: 13px;
    font-weight: var(--fontWeightRegular);
  }
}

.root h2 {
  /* Adjust heading margins to work with the reduced body font size */
  margin: 29px 0 13px 0;

  @media (--viewportMedium) {
    margin: 32px 0 0 0;
  }

  @media (--maxViewportSmall) {
    font-size: 16px;
  }
}

.root .lastUpdated {
  @apply --marketplaceBodyFontStyles;
  margin-top: -10px;
  margin-bottom: 55px;
  font-size: 15px;
  @media (--viewportMedium) {
    margin-top: -16px;
    margin-bottom: 54px;
  }

  @media (--maxViewportSmall) {
    margin-top: 0;
    margin-bottom: 35px;
  }
}

.orderedList{
  padding-left: 15px;
  list-style: decimal;
}

.orderedList .listItem {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--matterColor);
}

.orderedList .listItem > p{
  font-size: 14px;
  color: #616b78;
  font-weight: 400;
  margin: 10px 0;
  line-height: 1.8;
  text-align: justify;
}

.unorderedList {
  padding-left: 20px;
}

.unorderedList > li{
  font-size: 14px;
  color: #616b78;
  font-weight: 400;
  margin: 10px 0;
  line-height: 1.8;
  text-align: justify;
  display: list-item;
  list-style: disc;
}