@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.valuationInput input[type='number'] {
  width: 324px;
  height: 59px;
  flex-grow: 0;
  padding: 17px 20px 16px 20px;
  border-radius: 4px;
  border: 1px solid rgba(151, 151, 151, 0.36);
  font-size: 22px;
  margin-bottom: 15px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.valuationInput input[type='number']::placeholder {
  color: var(--matterColor);
  font-size: 22px;
}

/* Chrome, Safari, Edge, Opera */
.valuationInput input::-webkit-outer-spin-button,
.valuationInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.valuationInput input[type='number'] {
  -moz-appearance: textfield;
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.valuationConfirmation {
  margin: 0;
  font-size: 30px;
  text-align: center;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: -0.9px;
  padding: 40px;
  padding-bottom: 0;
  max-width: 460px;
  color: var(--matterColor);

  @media (--maxViewportMedium) {
    font-size: 24px;
    line-height: 40px;
  }

  @media (--maxViewportSmall) {
    font-size: 20px;
    line-height: 36px;
    max-width: 400px;
    padding: 20px;
  }
}

.valuationError {
  color: var(--failColor);
  margin: 0;
  /* display: inline-block; */
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  line-height: 24px;
  margin-top: 0px;
  @media (--maxViewportSmall) {
    font-size: 14px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.valuationNowButton {
  width: 100%;
  margin: 0 0 30px;
}

.valuationPlacedSubTitle {
  margin-bottom: 15px;
}

.valuationPlacedSubTitle h3 {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  font-weight: var(--fontWeightLight);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
  }

  @media (--viewportDesktop) {
    font-size: 16px;
  }

  @media (--maxViewportSmall) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.valuationPlacedSubTitle span {
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
  @media (--maxViewportSmall) {
    font-size: 16px;
    margin-top: 0px;
  }
}

.separator {
  margin: 0 6px;
}

.seeValuationListLink {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
}

.seeValuationListLink:hover {
  text-decoration: underline;
}

.valuationPlacedDialogContent {
  text-align: center;
  padding: 40px 30px;
  padding-bottom: 0;
  max-width: 520px;
  color: var(--matterColor);

  @media (--maxViewportMedium) {
    padding: 20px;
  }
  @media (--maxViewportSmall) {
    padding: 20px 0;
  }
}

.valuationPlacedDialogContent h4 {
  font-size: 30px;
  line-height: 48px;
  letter-spacing: -0.9px;
  font-weight: var(--fontWeightLight);
  @media (--maxViewportMedium) {
    font-size: 24px;
    line-height: 40px;
  }
  @media (--maxViewportSmall) {
    font-size: 22px;
    line-height: 36px;
  }
}

.valuationPlacedDialogContent p {
  font-size: 20px;
  line-height: 40px;
  font-weight: var(--fontWeightLight);
  @media (--maxViewportMedium) {
    font-size: 18px;
    line-height: 36px;
  }
  @media (--maxViewportSmall) {
    font-size: 16px;
    line-height: 28px;
  }
}

.btn_main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_main:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after,
.btn_main .loader.threeDots {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load-threeDots 1.5s infinite ease-in-out;
  animation: load-threeDots 1.5s infinite ease-in-out;
}
.btn_main .loader.threeDots {
  margin: auto;
  font-size: 5px;
  position: relative;
  top: -10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.btn_main .loader.threeDots:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.btn_main .loader.threeDots:after {
  left: 2.5em;
}
.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
@keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
