.root {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0;
  margin-bottom: 70px;
  @media (--maxViewportSmall) {
    margin-bottom: 0;
  }
}

.showMore {
  width: 200px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--marketplaceColor);
  background-color: #fff;
  color: var(--marketplaceColor);
  transition: all 0.15s ease-out;
  margin: 50px auto 0 auto;
}

.showMore:hover {
  background-color: var(--marketplaceColor);
  color: #fff;
}

.filterSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 1.6rem;
  @media (--maxViewportMedium) {
    padding: 0 1.6rem;
  }
}

.filterSection p {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 0;
  @media (--maxViewportMedium) {
    margin: 0;
  }
}

.listingCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -45px;
  @media (--maxViewportMedium) {
    margin: 0 -25px;
  }
  @media (--viewportDesktop) {
    margin: 0;
  }
}

.listingCards li {
  /* width: calc(100%/3); */
  padding: 0 45px;

  @media (--maxViewportLarge) {
    width: calc(100% / 2);
  }
  @media (--maxViewportSmall) {
    width: 100%;
  }
}
.liWidthDashboard {
  width: calc(100% / 2);
}
.liWidth {
  width: calc(100% / 3);
}
.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

@media (--viewportDesktop) {

  .listingCards li {
    padding: 0 25px;
  }
}
