@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  font-family: 'Prompt', sans-serif !important;
  font-weight: var(--fontWeightRegular);
  font-size: 15px;
}

.label:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.label .expandMoreIcon{
  fill:var(--matterColorPlaceholder);
  margin-left: 20px;
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
}

.labelSelected:hover,
.labelSelected:focus {
  border: 1px solid var(--marketplaceColorDark);
}
