.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  font-weight: var(--fontWeightLight);
}

.select > option {
  color: var(--matterColor);
}
.select > option:disabled {
  color: var(--matterColorAnti);
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.selectSuccess::before {
  border-bottom-color: var(--successColor) !important;
  border-bottom-width: 2px !important;
}

.Mui-focused::before {
  border-bottom-color: var(--successColor) !important;
  border-bottom-width: 2px !important;
}

.selectSuccess::after {
  border-bottom-color: var(--successColor) !important;
}
