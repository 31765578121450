@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(40% - 9px);
}

.lastNameRoot {
  width: calc(60% - 9px);
}

.password,
.building {
  @apply --marketplaceModalPasswordMargins;
  position: relative;
}

.password svg {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 7px;
  top: 36px;
  cursor: pointer;
  @media (--maxViewportMedium) {
    top: 25px;
  }
}

.phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;
}

.termsLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom-color: var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress {
  flex-shrink: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.locationRoot {
  flex-shrink: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.signupBtn {
  @media (--maxViewportSmall) {
    font-size: 14px;
    padding: 12px 30px;
  }
}
