.container {
  /* Layout */
  /* width: 100%;
    flex-grow: 1;
    display: flex; */

  max-width: 1440px;
  margin: 0 auto;
  padding: 0 25px;
  width: 100%;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    /* min-height: calc(100vh - var(--topbarHeightDesktop)); */
  }

  @media (--maxViewportSmall) {
    padding-top: 16px;
  }
}
.loaderContainer {
  display: flex;
  padding-top: 150px;
  flex: 1 1;
}
.loaderContainer h3 {
  font-size: 1.5em;
  font-weight: var(--fontWeightMedium);
}

.loader_main {
  height: fit-content;
  width: fit-content;
  display: flex;
  color: var(--primaryColorDark);
  justify-content: center;
  align-items: center;
}

.loader_main .loader.threeDots:before,
.loader_main .loader.threeDots:after,
.loader_main .loader.threeDots {
  border-radius: 50%;
  width: 1.5em;
  height: 1.6em;
  left: 20px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load-threeDots 1.5s infinite ease-in-out;
  animation: load-threeDots 1.5s infinite ease-in-out;
}
.loader_main .loader.threeDots {
  margin: auto;
  font-size: 5px;
  position: relative;
  top: -10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader_main .loader.threeDots:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader_main .loader.threeDots:after {
  left: 2.5em;
}
.loader_main .loader.threeDots:before,
.loader_main .loader.threeDots:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--primaryColorDark);
  }
  40% {
    box-shadow: 0 2.5em 0 0 var(--primaryColorDark);
  }
}
@keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--primaryColorDark);
  }
  40% {
    box-shadow: 0 2.5em 0 0 var(--primaryColorDark);
  }
}
