.app-content {
    text-align:  center;
    background-color:  white;
    min-height:  100vh;
    display:  flex;
    flex-direction:  column;
    align-items:  center;
    justify-content:  center;
    font-size:  calc(10px  +  2vmin);
    color:  white;
  }
  
  .page-container {
    margin:  1em;
  }
  
  .neat-style {
    color:  rgb(255, 142, 166);
    font-weight:  bold;
    text-decoration:  underline;
  } 
  
  .button-area  button {
    margin-right:  10px;
  }
  
  .k-pdf-export {
    background-color:  white;
  }