@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
  font-size: 13px;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }

  @media (--maxViewportSmall) {
    font-size: 12px;
    line-height: 18px;
    margin: 10px 0;
  }
}