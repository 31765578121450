.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 30px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  /* flex-shrink: 0;
  flex: 1;
  padding-right: 20px; */

  /* @media (--maxViewportSmall) {
    width: 100%;
    padding-right: 0;
  } */
}

.verifyBtn {
  max-width: 237px;
  padding: 0 20px;
  font-size: 14px;
  letter-spacing: -0.2px;
  margin-top: 5px;
  @media (--maxViewportMedium) {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.category {
  composes: formMargins;
}

.features {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  @media (--maxViewportSmall) {
    padding: 12px 30px;
    font-size: 14px;
  }
}

.choiceInbetween {
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
}

.inlineFormFieldWrapper {
  display: flex;
  align-items: flex-start;
  @media (--maxViewportSmall) {
    flex-direction: column;
  }
}

.btn_main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_main:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after,
.btn_main .loader.threeDots {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load-threeDots 1.5s infinite ease-in-out;
  animation: load-threeDots 1.5s infinite ease-in-out;
}
.btn_main .loader.threeDots {
  margin: auto;
  font-size: 5px;
  position: relative;
  top: -10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.btn_main .loader.threeDots:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.btn_main .loader.threeDots:after {
  left: 2.5em;
}
.btn_main .loader.threeDots:before,
.btn_main .loader.threeDots:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
@keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #fff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #fff;
  }
}
